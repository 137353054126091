import BaseReactComponent from "../core/base-react.component";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import CartService from "../services/cart.service";
import { CheckoutController } from "../controllers/checkout.controller";
import CheckoutService from "../services/checkout.service";
import { LayoutController } from "../controllers/layout.controller";
import { ViewportController } from "../controllers/viewport.controller";
import { DomComponent } from "../core/dom.component";
import PromotionCodeList from "../components/promotion-code-list.component";
import { LinearProgress } from "./linear-progress.component";
import AccountService from "../services/account.service";
import WishListService from "../services/wishlist.service";
import CartRecommendationsComponent from "./cart-recommendations.component";
import RecSys from "../services/recys.service";
import ShippingMethodListComponent from "./shipping-method-list.component";
import initAfterpay from "./afterpay.component";
import { PaypalBraintreeButton } from "./paypal-braintree-button.component";
import CartItemTable from "./cart-item-table.component";

export class OrderSummaryBoxComponent extends DomComponent {
  constructor(app, element) {
    super(app, element);
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.component = ReactDOM.render(
      <OrderSummaryBox app={this.app} currentProps={this.getAttributes()} />,
      this.domElement
    );
  }
}

OrderSummaryBoxComponent.decorator = {
  selector: "fsd-order-summary-box",
};

export class OrderSummaryBox extends BaseReactComponent {
  constructor(props, context) {
    super(props, context, {
      defaultProps: {
        checkouturl: null,
      },
    });
    this.i18nScopeKey = "ORDER_SUMMARY_BOX";
    this.domElement = null;
    this.renderStore = {
      noWishlist: null
    }
    this.uploadTaxExemptionInput;
    this.uploadTaxExemptionClick =
      this.uploadTaxExemptionClickHandler.bind(this);
    this.uploadTaxExemptionInputChange =
      this.uploadTaxExemptionInputChangeHandler.bind(this);
    this.placeYourOrderClick = this.placeYourOrderClickHandler.bind(this);
    this.onScroll = this.scrollHandler.bind(this);
    this.payaplCheckoutRedirectHandler = this.payaplCheckoutRedirectHandler.bind(this);
    this.afterpayInitiated = false;
    this.state = {
      isLoading: false,
      isValid: false,
      cart: null,
      currentShippingPrice: 0,
      isOrderProcess: true,
      isLoading: true,
      showRcys: true,
      hasPlaceYourOrder: props.hasPlaceYourOrder,
      isShrink: false,
      isExpanded: false,
      discountAmount: 0,
      coupons: null,
      isMobile: false,
      showTax: false,
      tax_exemption_file: null,
      wishlist: null,
    };
  }

  get hasDiscount() {
    return this.state.discountAmount && Math.abs(this.state.discountAmount) > 0;
  }

  appOnInit() {
    if (super.appOnInit) super.appOnInit();
    this.i18nService = this.app.i18nService;
    this.accountService = this.app.accountService;
    this.cartService = this.app.cartService;
    this.recSysService = this.app.recSysService;
    this.checkoutController = this.app.getController("checkout");
    this.checkoutService = this.checkoutController.checkoutService;
    this.layoutController = this.app.getController("layout");
    this.viewportController = this.app.getController("viewport");
    this.routerController = this.app.getController("router");
    this.dialogController = this.app.getController("dialog");
    this.wishListService = this.app.wishlistService;
    this.inputTax = React.createRef();
    this.accountService.addEventListener(
      AccountService.EVENT_USER_CHANGE,
      this.accountUserChangeHandler.bind(this)
    );
    this.cartService.addEventListener(
      CartService.EVENT_CHANGE,
      this.cartChangeHandler.bind(this)
    );
    this.cartService.addEventListener(
      CartService.EVENT_REQUIRED_AMOUNT_FOR_FREE_SHIPPING_CHANGE,
      this.requiredAmountChangeHandlerProxyListener.bind(this)
    );
    this.checkoutService.addEventListener(
      CheckoutService.EVENT_SHIPPING_METHOD_CHANGE,
      this.currentShippingChangeHandler.bind(this)
    );
    this.checkoutController.addEventListener(
      CheckoutController.EVENT_STEP_CHANGE,
      this.checkoutStepChangeHandler.bind(this)
    );
  }

  componentDidMount() {
    this.layoutScrollProxyListener = this.layoutController.addEventListener(
      LayoutController.EVENT_SCROLL,
      this.onScroll
    );
    this.viewportBreakpointChangeProxyListener =
      this.viewportController.addEventListener(
        ViewportController.EVENT_BREAKPOINT_CHANGE,
        this.onScroll
      );
    this.recSysService.addEventListener(RecSys.EVENT_CHANGE, () =>
      this.setState({
        showRcys:
          this.recSysService.faqData &&
          this.recSysService.faqData.products &&
          this.recSysService.faqData.products.length,
      })
    );
    this.wishListService.addEventListener(
      WishListService.EVENT_CHANGE,
      this.wishlistData.bind(this)
    );
    this.cartChangeHandler();
    this.onScroll();
    this.app
      .findChild('[href="#uploadTaxExemption"]')
      .then((element) => {
        console.log(element, "element");

        this.app.findChild("#uploadTaxExemptionInput").then((input) => {
          this.uploadTaxExemptionInput = input;
          this.uploadTaxExemptionInput.onchange =
            this.uploadTaxExemptionInputChange;
          element.onclick = this.uploadTaxExemptionClick;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  payaplCheckoutRedirectHandler(){
    window.location.href = this.app.environment.route.checkout+"?paypal"
  }

  componentDidUpdate() {
    if (!this.afterpayInitiated && document.getElementById("afterpay-cart")) {
      initAfterpay("afterpay-cart", this.layoutController,this.dialogController);
      this.afterpayInitiated = true;
    }
  }
  wishlistData() {
    this.setState({
      wishlist: this.wishListService.list,
    });
  }
  accountUserChangeHandler(e) {
    this.user = this.accountService.user;

    if (this.user && this.user.is_partner_store === 0) {
      this.setState({
        tax_exemption_file: this.user.tax_exemption_file,
        showTax: true,
      });
    }
  }
  componentWillReceiveProps(props) {
    this.setState({ hasPlaceYourOrder: props.hasPlaceYourOrder });
  }

  componentWillUnmount() {
    if (this.layoutController && this.onScroll)
      this.layoutController.removeEventListener(
        LayoutController.EVENT_SCROLL,
        this.layoutScrollProxyListener
      );
    if (this.viewportController && this.onScroll)
      this.viewportController.removeEventListener(
        ViewportController.EVENT_BREAKPOINT_CHANGE,
        this.viewportBreakpointChangeProxyListener
      );
  }

  render() {
    this.renderStore.noWishlist = <div class="d-flex mt-3 pb-4 save_list-container" data-uw-styling-context="true">
                                        <div class="mr-2 flex-shrink-1 c-checkout-static-block-img" data-uw-styling-context="true">
                                        <i class="icon__fsd icon__fsd--empty-save-list" data-uw-styling-context="true"></i>
                                        </div>
                                        <div class="info-text-checkout" data-uw-styling-context="true">
                                            <h2 class="info-text-checkout-title" style={{fontSize:"21px"}} data-uw-styling-context="true">Your Saved List is Empty</h2>
                                            {this.accountService.isGuestUser && <p class="info-text-checkout-description empty-save-list-p" data-uw-styling-context="true">
                                                <a href="/customer/account/login/" data-uw-styling-context="true">Login </a> or {" "}<a href="/customer/account/create" data-uw-styling-context="true"> Create an account </a> to save some  items for later 
                                            </p>}
                                        </div>
                                    </div>
    if (!this.state.cart)
      return (
        <div className="c-order-summary-box">
          <div className="c-order-summary-box__container">
            <div className="c-order-summary-box__loading"></div>
          </div>
        </div>
      );
    // if (this.state.cart && this.state.cart.items && this.state.cart.items.length < 1) return (null);
    let progress = 0;
    let refrallAmount;
    if (
      this.state.cart &&
      this.state.cart.totals &&
      this.state.requiredAmountForFreeShipping > 0
    ) {
      progress = Math.round(
        (parseInt(this.state.cart.totals["subtotal"]) /
          parseInt(this.state.requiredAmountForFreeShipping)) *
          100
      );
      progress = Math.max(0, Math.min(progress, 100));
    }
    if (this.state.cart.totals) {
      refrallAmount =
        this.state.cart.totals.aw_raf === 0
          ? null
          : this.state.cart.totals.aw_raf;
      if (this.state.cart.totals.total_segments) {
        this.state.cart.totals.total_segments.map(({ code, value }) => {
          if (code === "aw_raf" && value) {
            refrallAmount = Math.abs(value);
          }
        });
      }
    }
    let componentClassName = classNames("c-order-summary-box", {
      "c-order-summary-box--order-process": this.state.isOrderProcess,
      "c-order-summary-box--place-your-order": this.state.hasPlaceYourOrder,
      "c-order-summary-box--shrink": this.state.isShrink,
      "c-order-summary-box--expanded": this.state.isExpanded,
      "c-order-summary-box--has-discount": this.hasDiscount,
      "c-order-summary-box--place-your-order":
        this.currentProps.checkouturl || this.state.hasPlaceYourOrder,
      "c-order-summary-box--not-checkout":
        this.currentProps.checkouturl != null,
    });

    let couponList;
    if (this.state.coupons) {
      couponList = this.state.coupons.map((coupon) => {
        return (
          <li
            key={coupon.code}
            className="c-order-summary-box__promotion-code-item"
          >
            <div className="c-order-summary-box__promotion-code-item-container">
              <div className="c-order-summary-box__promotion-code-item-left">
                <div className="c-order-summary-box__promotion-code-item-action">
                  <span
                    onClick={() => {
                      this.removeCoupon(coupon);
                    }}
                    className="c-order-summary-box__promotion-code-item-action-remove"
                  >
                    <i className="icon__fsd icon__fsd--trash-gray"></i>
                  </span>
                </div>
                <div className="c-order-summary-box__promotion-code-item-title">
                  <span>{coupon.title}</span>
                </div>
              </div>
              <div className="c-order-summary-box__promotion-code-item-right">
                <div className="c-order-summary-box__promotion-code-item-discount">
                  <span>
                    {this.i18nService.format(
                      coupon.value < 0 ? coupon.value * -1 : coupon.value,
                      ["number", "currency"]
                    )}
                  </span>
                </div>
              </div>
            </div>
          </li>
        );
      });
    }

    let actionButton;
    if (
      this.currentProps.checkouturl &&
      this.state.cart &&
      this.state.cart.totals
    ) {
      actionButton = (
        <div>
          <div
            className="c-order-summary-box__action-button"
            style={
              this.state.cart.isAfterpayPaymentAvailable 
                ? { marginTop: "0" }
                : {}
            }
            title={
              this.state.cart.totals.redirectTrue
                ? `Please check minimum requirement for seller(s): ${this.state.cart.totals.errorSeller.toString()}`
                : ""
            }
          >
            <button
              onClick={this.goToCheckoutClickHandler.bind(this)}
              className={classNames(
                "c-button",
                "btn-green",
                "c-button--full-width",
                !this.state.cart?.isAfterpayPaymentAvailable && "stylewithAfter" ,
                this.state.cart.totals.redirectTrue ? "c-button--disabled border-0" : ""
              )}
            >
              {this.i18nService.translate([
                this.i18nScopeKey,
                "GO_TO_CHECKOUT_LABEL",
              ])}
            </button>
          </div>
          {!this.state.cart.totals.redirectTrue && (
            <div className="mt-3 c-mini-cart-notification__action">
              <PaypalBraintreeButton color={"silver"} app={this.app} /> {/* TODO: will do later  */}
              {/* <button
                style={{height:"50px"}}
                onClick={this.payaplCheckoutRedirectHandler}
                className="c-button--full-width mb-3 paypal-static-btn checkout-afterpay-button d-flex justify-content-center align-items-center py-3"
              >
                <i className="icon__fsd icon__fsd--paypal-btn"></i>
                <span className="afterpayTXT">Checkout</span>
              </button> */}
            </div>
          )}
        </div>
      );
    } else {
      switch (this.props.paymentMethod) {
        case "paypal":
        case "paypal_express":
          actionButton = (
            <div className="c-order-summary-box__action-button"></div>
          );

          break;
        default:
          actionButton = (
            <div>
              <div
                className="c-order-summary-box__action-button"
                style={
                  this.state.cart.isAfterpayPaymentAvailable
                    ? { marginTop: "0" }
                    : {}
                }
              >
                <button
                  onClick={this.placeYourOrderClick}
                  className={classNames(
                    "c-button",
                    "c-button--primary",
                    "c-button--full-width"
                  )}
                >
                  {this.i18nService.translate([
                    this.i18nScopeKey,
                    "CHECKOUT_LABEL",
                  ])}
                </button>
              </div>
              {/* <div className="c-order-summary-box__terms-check">
                            <label dangerouslySetInnerHTML={{ __html: this.i18nService.translate([this.i18nScopeKey, 'TERMS_CHECK_LABEL'], {
                                privacyAnchor: `<a href="${this.app.environment.route.privacyPolicy}" target="_blank">${this.i18nService.translate([this.i18nScopeKey, 'PRIVACY_POLICY'])}</a>`, 
                                termsOfUseAnchor: `<a href="${this.app.environment.route.termsOfUse}" target="_blank">${this.i18nService.translate([this.i18nScopeKey, 'TERMS_OF_USE'])}</a>`}) }}></label> 
                        </div> */}
            </div>
          );
          break;
      }
    }

    return (
      <Fragment>
        <div ref={(r) => (this.domElement = r)} className={componentClassName}>
          <div className="c-order-summary-box__container">
            {this.state.isLoading && (
              <div className="c-order-summary-box__loading"></div>
            )}
            <div className="c-order-summary-box__caption">
              {/* <div onClick={this.expandClickHandler.bind(this)} className="c-order-summary-box__total"> */}

              {/* <div className="c-order-summary-box__code">
                            <span className="c-order-summary-box__code-title">{ this.i18nService.translate([this.i18nScopeKey, 'APPLY_CODE_LABEL']) }</span>
                            <span className="c-order-summary-box__code-expand icon__fsd icon__fsd--arrow-down-text-gray"></span>
                        </div> */}
              {/* {couponList && <div className="c-order-summary-box__promotion-code">
                            <ul className="c-order-summary-box__promotion-code-items">
                                {couponList}
                            </ul>
                        </div>} */}
              {this.state.cart.isAfterpayPaymentAvailable && this.state.cart.totals.grand_total <= 2000 && 
                <>
                  { !this.state.isShrink  && <afterpay-placement
                    data-locale="en_US"
                    data-badge-theme="black-on-mint"
                    data-intro-text="Pay in"
                    data-amount={this.state.cart.totals.grand_total}
                  ></afterpay-placement>
                  }

                  <button
                    data-afterpay-entry-point="entryPoint"
                    id="afterpay-cart"
                    className="c-button--full-width mb-3 checkout-afterpay-button d-flex justify-content-center align-items-center py-3"
                  >
                    <img
                      src="https://mcstaging4.foodservicedirect.net/images/logo/afterpay.png"
                      alt="Checkout with Afterpay"
                    />
                    <span className="afterpayTXT">Checkout</span>
                  </button>
                </>
              }
              {actionButton}
              {/* <PaypalButton app={this.app}/> */}
            </div>
            <div className="c-order-summary-box__content">
              <div className="c-order-summary-box__list">
                {/* <h4 className="c-order-summary-box__list-title">{this.i18nService.translate([this.i18nScopeKey, 'ORDER_SUMMARY_LABEL'])}</h4> */}
                <div className="c-order-summary-box__promotion-code-list">
                  <PromotionCodeList
                    app={this.app}
                    coupons={this.state.coupons}
                  />
                </div>
                <ul className="c-order-summary-box__items">
                  <li className="c-order-summary-box__item">
                    <div className="c-order-summary-box__item-caption">
                      <h5 className="c-order-summary-box__item-title">
                        {this.i18nService.translate([
                          this.i18nScopeKey,
                          "SUB_TOTAL_LABEL",
                        ])}
                      </h5>
                      {this.state.cart &&
                        this.state.cart.totals &&
                        parseFloat(
                          this.state.cart.totals["subtotal_with_discount"]
                        ) < parseFloat(this.state.cart.totals["subtotal"]) && (
                          <span className="c-order-summary-box__item-price-old">
                            {this.i18nService.format(
                              this.state.cart && this.state.cart.totals
                                ? parseFloat(this.state.cart.totals["subtotal"])
                                : "",
                              ["number", "currency"]
                            )}
                          </span>
                        )}
                      <span className="c-order-summary-box__item-price">
                        {this.i18nService.format(
                          this.state.cart && this.state.cart.totals
                            ? parseFloat(
                              this.state.cart?.totals?.shipping_discount_amount ? this.cartService.getDiscountedSubtotal(this.state.cart?.totals) : this.state.cart.totals["subtotal_with_discount"]
                              )
                            : "",
                          ["number", "currency"]
                        )}
                      </span>
                    </div>
                  </li>
                  {this.state?.cart?.totals?.cold_pack_fee ? 
                  <li className="c-order-summary-box__item">
                      <div className="c-order-summary-box__item-caption">
                          <h5 className="c-order-summary-box__item-title cold-pack-fee">
                            Cold Pack Fee
                            <i class="icon__fsd icon__fsd--info-gray">
                              <div class="msg_box">
                                <p>Some of the Frozen and Refrigerated items may incur extra packaging costs like cooler box, dry ice, gel pack and additional carrier weight
                                </p>
                              </div>
                            </i>
                          </h5>
                          <span className="c-order-summary-box__item-price">
                          {this.i18nService.format(this.state?.cart?.totals?.cold_pack_fee, ['number', 'currency'])}
                          </span>
                      </div>
                  </li>:""}
                  {this.state.cart &&
                    parseFloat(this.state.cart.totals.customerbalance) < 0 && (
                      <li className="c-order-summary-box__item">
                        <div className="c-order-summary-box__item-caption">
                          <h5 className="c-order-summary-box__item-title">
                            {this.i18nService.translate([
                              this.i18nScopeKey,
                              "STORE_CREDIT_LABEL",
                            ])}
                          </h5>
                          <span className="c-order-summary-box__item-price">
                            {this.i18nService.format(
                              this.state.cart && this.state.cart.totals
                                ? parseFloat(
                                    this.state.cart.totals["customerbalance"]
                                  )
                                : "",
                              ["number", "currency"]
                            )}
                          </span>
                        </div>
                      </li>
                    )}
                  <li className="c-order-summary-box__item">
                    <div className="c-order-summary-box__item-caption">
                      <span className="c-order-summary-box__item-title">
                        {this.i18nService.translate([
                          this.i18nScopeKey,
                          "SALES_TAX_LABEL",
                        ])}
                      </span>
                      <span className="c-order-summary-box__item-price">
                        {this.i18nService.format(
                          this.state.cart
                            ? this.state.cart.totals["tax_amount"]
                            : "",
                          ["number", "currency"]
                        )}
                      </span>
                    </div>
                  </li>
                  {this.state.currentShippingPrice ? <li className="c-order-summary-box__item">
                    <div className="c-order-summary-box__item-caption">
                      <span className="c-order-summary-box__item-title">
                        {this.i18nService.translate([
                          this.i18nScopeKey,
                          "SHIPPING_LABEL",
                        ])}
                            <i class="icon__fsd icon__fsd--info-gray-icon">
                              <div class="msg_box">
                                <p>Actual shipping fees will be calculated at checkout after your address has been verified
                                </p>
                              </div>
                            </i>
                      </span>
                      {this.state.cart?.totals?.shipping_discount_amount ?  
                      <span className="c-order-summary-box__item-price-old">
                            {this.i18nService.format(
                              this.state.currentShippingPrice,
                              ["number", "currency"]
                            )}
                      </span>:null
                      }
                      <span className="c-order-summary-box__item-price">
                        {this.i18nService.format(
                          this.state.cart?.totals?.shipping_discount_amount ? (this.state.cart?.totals?.shipping_amount - this.state.cart?.totals?.shipping_discount_amount) : this.state.currentShippingPrice,
                          ["number", "currency"]
                        )}
                      </span>
                    </div>
                  </li>:null}

                  {refrallAmount && (
                    <li className="c-order-summary-box__item">
                      <div className="c-order-summary-box__item-caption">
                        <h5 className="c-order-summary-box__item-title text-success">
                          Referral Rewards
                        </h5>
                        <span className="c-order-summary-box__item-price text-success">
                          {this.i18nService.format(parseFloat(refrallAmount), [
                            "number",
                            "currency",
                          ])}
                        </span>
                      </div>
                    </li>
                  )}
                  <li className="c-order-summary-box__total">
                    <h4 className="c-order-summary-box__total-title">
                      {this.i18nService.translate([
                        this.i18nScopeKey,
                        "TOTAL_LABEL",
                      ])}
                    </h4>
                    <div className="c-order-summary-box__total-price">
                      <div className="c-order-summary-box__total-price-value">
                        {this.state?.cart?.totals?.discount_amount ? <span className="c-order-summary-box__total-price-value-sub">
                          {this.i18nService.format(
                            this.state.cart && this.state.cart.totals
                              ? this.cartService.setGrandTotal(
                                  this.state.cart.totals
                                ) -
                                  parseFloat(
                                    this.state.cart.totals["discount_amount"]
                                  )
                              : "",
                            ["number", "currency"]
                          )}
                        </span> : null}
                        <span className="c-order-summary-box__total-price-value-grand">
                          {this.i18nService.format(
                            this.state.cart && this.state.cart.totals
                              ? this.cartService.setGrandTotal(
                                  this.state.cart.totals
                                )
                              : "",
                            ["number", "currency"]
                          )}
                        </span>
                      </div>
                      {this.state.cart &&
                        this.state.cart.totals &&
                        parseFloat(this.state.cart.totals["discount_amount"]) <
                          0 && (
                          <div className="c-order-summary-box__total-price-discount">
                            {this.i18nService.translate(
                              [this.i18nScopeKey, "SAVE_LABEL"],
                              {
                                saveAmount:
                                  -1 *
                                  parseFloat(
                                    this.state.cart.totals["discount_amount"]
                                  ),
                              }
                            )}
                          </div>
                        )}
                    </div>
                    {/* <div className="c-order-summary-box__total-order-detail">
                                <a href="#" className="c-order-summary-box__total-order-detail-link">Order Detail</a>
                            </div> */}
                  </li>
                  {this.state.showTax && !this.accountService.isGuestUser && <li className="c-order-summary-box__item-tax-detail">
                                        <span className="c-order-summary-box__item-info">
                                            <strong>{this.i18nService.translate([this.i18nScopeKey, 'BUSINESS_OWNER_LABEL'])}</strong>

                  {/* <span dangerouslySetInnerHTML={{ __html: this.i18nService.translate([this.i18nScopeKey, 'UPLOAD_TAX_EXEMPTION_FORM'], {uploadAnchor: `<a href="#uploadTaxExemption">${this.i18nService.translate([this.i18nScopeKey, 'UPLOAD_LABEL'])}</a>`}) }}></span>  */}

                  <div>{this.state.tax_exemption_file ? <span className="pr-1"><a target="_blank" href={this.state.tax_exemption_file}>Download</a> or </span> : '' }<a className="pr-1" onClick={()=>this.inputTax.click()} href="javascript:void(0)">{this.i18nService.translate([this.i18nScopeKey, 'UPLOAD_LABEL'])} </a> {this.i18nService.translate([this.i18nScopeKey, 'UPLOAD_TAX_EXEMPTION_FORM'])}</div>
                                            <input accept="application/pdf,application/vnd.ms-excel" ref={(input)=> this.inputTax = input} onChange={this.uploadTaxExemptionInputChange} type="file" id="uploadTaxExemptionInput"/>
                                        </span>
                                    </li>}
                </ul>
              </div>
            </div>
            {/* <div className="c-order-summary-box__separator"></div> */}
          </div>
        </div>
        <div className="mt-3 c-checkout-static-block c-order-summary-box-details">
          {/* <h2  className = "info-text-checkout-title">Super Secure Shopping</h2> */}
          {/* {!this.state?.wishlist?.items?.length  && <div class="d-flex mt-3 pb-4" data-uw-styling-context="true">
                        <div class="mr-2 flex-shrink-1 c-checkout-static-block-img" data-uw-styling-context="true">
                         <i class="icon__fsd icon__fsd--empty-save-list" data-uw-styling-context="true"></i>
                        </div>
                        <div class="info-text-checkout" data-uw-styling-context="true">
                            <h2 class="info-text-checkout-title" data-uw-styling-context="true">Your Saved List is Empty</h2>
                            <p class="info-text-checkout-description empty-save-list-p" data-uw-styling-context="true">
                                <a href="/customer/account/login/" data-uw-styling-context="true">Login </a> or {" "}<a href="/customer/account/create" data-uw-styling-context="true"> Create an account </a> to save some  items for later 
                            </p>
                        </div>
                    </div>} */}

          <div className="d-flex mt-3 pb-4">
            <div className="mr-2 flex-shrink-1 c-checkout-static-block-img">
              <i class="icon__fsd icon__fsd--secure" />
            </div>
            <div className="info-text-checkout">
              <h2 className="info-text-checkout-title">
                Super Secure Shopping
              </h2>
              <p className="info-text-checkout-description">
                Your information is always secure.
              </p>
            </div>
          </div>
          {/* <h2><a  target="_blank" href="
                        https://foodservicedirect.zendesk.com/hc/en-us/articles/360009183214-How-will-my-items-be-shipped-">Frozen Shipping</a></h2> */}
          {/* <h2  className = "info-text-checkout-title">Frozen Shipping</h2> */}
          <div className="d-flex mt-3 pb-4">
            <div className="mr-2 c-checkout-static-block-img">
              <i class="icon__fsd icon__fsd--ships-frozen-no-border" />
            </div>
            <div className="info-text-checkout w-75">
              <h2 className="info-text-checkout-title">
                <a
                  target="_blank"
                  href="https://foodservicedirect.zendesk.com/hc/en-us/articles/360009183214-How-will-my-items-be-shipped-"
                  data-uw-styling-context="true"
                  data-uw-rm-brl="false"
                  aria-label="Frozen Shipping - opens in new tab"
                  data-uw-rm-ext-link=""
                  uw-rm-external-link-id="https://foodservicedirect.zendesk.com/hc/en-us/articles/360009183214-how-will-my-items-be-shipped-$frozenshipping"
                  // style="transition: all 0s ease 0s;"
                >
                  Frozen Shipping
                </a>
              </h2>
              <p className="info-text-checkout-description">
              You will receive a quality shipment or we will replace it free of charge.
              </p>
            </div>
          </div>
          {/* <h2  className = "info-text-checkout-title">Customer Service</h2> */}
          <div className="d-flex mt-3 ">
            <div className="mr-2 c-checkout-static-block-img">
              <i class="icon__fsd icon__fsd--call_center" />
            </div>
            <div className="info-text-checkout w-75">
              <h2 className="info-text-checkout-title">Customer Service</h2>
              <p className="info-text-checkout-description">
                Call us at +1 855 705 0755  from 8am-8pm EST Monday-Friday, and
                we’ll help you complete your order.
              </p>
              <a
                target="_blank"
                href={this.app.environment.route.faq}
                className="a-link"
              >
                Visit our FAQs
              </a>
            </div>
          </div>
        </div>
        {this.state.isMobile ? (
          <>
            <div className="p-cart-detail__cart c-cart__container mt-4">
              {this.state?.wishlist?.items?.length ?
              <div className="c-cart__section saved-list_section">
                  <div className="text-22 mb-3 saved-list-title ">Saved for Later</div>
                  <div className="c-cart__section-content">
                        <CartItemTable 
                          app={this.app}
                          saveforLater ={true} 
                          items={this.state.wishlist ? this.state.wishlist.items : null}
                          onRemoveItemClick={this.removeItemClickHandler.bind(this)}
                          onItemQtyChange={this.updateItemQtyHandler.bind(this)}
                        />
                  </div>
              </div>
              :
              this.renderStore.noWishlist
              } 
            </div>
            <div className="cart_cust_bought_sec">
              <CartRecommendationsComponent
                isSlider={false}
                isUnbxd={false}
                elementId="cart-recysys"
                app={this.app}
                limit={6}
              />
              {/* <CartRecommendationsComponent cartItems={this.state.cart.items} isUnbxd={true} elementId="cart-unbxd" app={this.app} /> */}
            </div>
          </>
        ) : (
          ""
        )}
      </Fragment>
    );
  }

  uploadTaxExemptionClickHandler(e) {
    e.preventDefault();
    if (this.uploadTaxExemptionInput) this.uploadTaxExemptionInput.click();
  }

  uploadTaxExemptionInputChangeHandler(event) {
    let file = event.dataTransfer
      ? event.dataTransfer.files[0]
      : event.target.files[0];
    if (!file.type.includes("pdf")) {
      this.dialogController.notify({
        theme: "danger",
        text: "Invalid Format",
      });
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const sendFile = {
        file: {
          content: {
            attribute_code: "tax_exemption_file",
            value: {
              base64EncodedData: reader.result,
              type: file.type,
              name: file.name,
            },
          },
        },
      };

      this.app.accountService
        .uploadTaxExemption(sendFile)
        .then((response) => {
          this.setState({ tax_exemption_file: response.data.url });
          if (response.data.status) {
            this.cartService.createCart().then((res) => {});
          }
          this.dialogController.notify({
            theme: response.data.status ? "success" : "danger",
            text: response.data.message,
          });
        })
        .catch((err) => {
          this.dialogController.notify({
            theme: "danger",
            text: "File not uploaded",
          });
        });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  cartChangeHandler() {
    this.setState(
      {
        cart: this.cartService.cart,
        coupons: this.cartService.coupons,
        discountAmount:
          this.cartService.cart && this.cartService.totals
            ? this.cartService.cart.totals.discount_amount
            : 0,
        isLoading: this.cartService.cart == null,
        currentShippingPrice:
          this.cartService.cart && this.cartService.cart.totals
            ? this.cartService.cart.totals.shipping_amount
            : 0,
        requiredAmountForFreeShipping:
          this.cartService.requiredAmountForFreeShipping,
      },
      () => {
        setTimeout(() => {
          this.onScroll();
        }, 150);
      }
    );
  }

  requiredAmountChangeHandlerProxyListener() {
    this.setState({
      requiredAmountForFreeShipping:
        this.cartService.requiredAmountForFreeShipping,
    });
  }

  currentShippingChangeHandler() {
    this.setState({
      currentShippingPrice: this.checkoutService.currentShippingMethod
        ? this.checkoutService.currentShippingMethod["amount"]
        : 0,
    });
  }
  checkoutStepChangeHandler() {
    this.setState({
      hasPlaceYourOrder:
        this.checkoutController.currentStep &&
        this.checkoutController.currentStep.name == "review",
    });
  }

  placeYourOrderClickHandler() {
    this.checkoutService.placeOrder(false);
  }

  expandClickHandler() {
    this.state.isExpanded ? this.collapse() : this.expand();
  }

  expand() {
    this.setState({ isExpanded: true });
    this.layoutController.enableHeaderSearchBar(false);
    this.layoutController.lockScroll();
  }

  collapse() {
    this.setState({ isExpanded: false });
    this.layoutController.enableHeaderSearchBar(true);
    this.layoutController.unlockScroll();
  }

  scrollHandler() {
    if (this.domElement) {
      let parentTop = this.domElement.parentNode.getBoundingClientRect().top;
      let offsetHeight = this.layoutController.getHeight() - 60;
      let isShrink =
        parentTop - offsetHeight > 0 && this.viewportController.isMobile;
      if (isShrink != this.state.isShrink)
        this.setState({ isShrink: isShrink });
    }
    this.setState({
      isMobile: this.viewportController.isMobile,
    });
  }

  removeCoupon(coupon) {
    let coupons = this.state.coupons;
    let indexOfCoupon = coupons.indexOf(coupon);
    this.cartService.removeCoupon(coupon.code).then((result) => {
      coupons.splice(indexOfCoupon, 1);
      this.setState({
        coupons: coupons,
      });
    });
  }

  goToCheckoutClickHandler(e) {
    console.error(this.currentProps);
    this.routerController.href("/checkout");
  }

  removeItemClickHandler(item) {
    if(item) {
        this.cartService.removeFromCart(item.item_id);
    }
  }

  updateItemQtyHandler(item) {
      if(item) {
          this.cartService.updateItemQty(item);
      }
  }

}
